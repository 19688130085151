import * as React from "react";
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="w-screen flex flex-col inset-x-0 top-0">
        <div className='pt-24'>
          <h1 className='text-4xl text-center'>404</h1>
          <h3 className='text-xl text-center'>Page not found</h3>
        </div>
      </div>
      </Layout>
  )
}

export default NotFoundPage;

export function Head() {
  return(
    <Seo />
  );
}